import React from 'react';


function Retry() {
    const user = JSON.parse(sessionStorage.getItem('user'));
    const firstName = user?.displayName?.split(' ')[0] || 'User';

    const buttonStyle = {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '15px 40px',
        fontSize: '16px',
        backgroundColor: '#000000', // Black background
        color: 'white',
        border: 'none',
        borderRadius: '40px',
        cursor: 'pointer',
        marginTop: '20px',
        fontFamily: 'Arial, sans-serif',
        fontWeight: 'bold',
        textDecoration: 'none',

    };



    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <img src="logo1.svg" alt="Logo" style={{ marginBottom: '20px' }} />
            <h2>Sorry, We could not authenticate your account.</h2>
            <a href="https://mailseen.live" style={buttonStyle}>
                Retry
            </a>
        </div>
    );
}

export default Retry;
