import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Login';
import Success from './Success';
import Retry from './Retry';
import Paid from './Paid';
import Paymentdone from './Paymentdone';



function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/success" element={<Success />} />
                <Route path="/retry" element={<Retry />} />
                <Route path="/paid" element={<Paid />} />
                <Route path="/paymentdone" element={<Paymentdone />} />

            </Routes>
        </Router>
    );
}

export default App;
