import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBJkowFKfj1EoDcRtdLF_4hOIh3eRX0D3s",
    authDomain: "mailseen.firebaseapp.com",
    projectId: "mailseen",
    storageBucket: "mailseen.appspot.com",
    messagingSenderId: "429196436219",
    appId: "1:429196436219:web:1db67c7a81a506faa4065e",
    measurementId: "G-S6ZB1T85ZM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth, onAuthStateChanged };
