import React from 'react';

function Paymentdone() {
    const user = JSON.parse(sessionStorage.getItem('user'));
    const firstName = user?.displayName?.split(' ')[0] || 'User';


    const containerStyle = {
        textAlign: 'center',
        marginTop: '50px',
        width: '100vw', // full viewport width
        height: '100vh', // full viewport height
        backgroundImage: 'url(bg.svg)', // Background image
        backgroundSize: 'cover', // Cover the entire viewport
        backgroundPosition: 'center', // Center the background image
        backgroundRepeat: 'no-repeat', // Do not repeat the background image
    };

    const buttonStyle = {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '15px 40px',
        fontSize: '16px',
        backgroundColor: '#000000', // Black background
        color: 'white',
        border: 'none',
        borderRadius: '40px',
        cursor: 'pointer',
        marginTop: '20px',
        fontFamily: 'Inter, sans-serif',
        fontWeight: 'bold',
        textDecoration: 'none',
    };

    const iconStyle = {
        marginRight: '10px',
        maxWidth: '30px',
        fontFamily: 'Inter, sans-serif',
    };

    const textStyle1 = {
        marginTop: '20px',
        fontSize: '14px', // Smaller font size
        fontFamily: 'Inter, sans-serif',
        fontWeight: 'normal',
        marginBottom: '140px',
    };


    const textStyle2 = {
        marginTop: '20px',
        fontSize: '14px', // Smaller font size
        fontFamily: 'Inter, sans-serif',
        fontWeight: 'normal',
    };

    return (
        <div style={containerStyle}>
            <img src="logo1.svg" alt="Logo" style={{ marginBottom: '50px', maxWidth: '200px' }} />
            <h2>Premium activated!</h2>
            <p style={textStyle1}>Enjoy Unlimited Historical data, Unlimited analytics and up to 5 accounts.</p>

            <a href="https://mail.google.com" style={buttonStyle}>
                <img src="gmailicon.png" alt="Gmail Icon" style={iconStyle} />
                Open your GMAIL
            </a>

            <p style={textStyle2}>If you wish to add new emails send us an email to hello@mailseen.com</p>
        </div>
    );
}

export default Paymentdone;
