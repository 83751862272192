import React, { useEffect, useState } from 'react';
import { auth } from './firebaseConfig';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './App.css';

function Paid() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false); // State to manage loading during login and API call

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                sessionStorage.setItem('user', JSON.stringify(user));
            } else {
                sessionStorage.removeItem('user');
            }
        });
    }, []);

    const handleLogin = async () => {
        setLoading(true); // Set loading true when login starts
        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            const { email } = result.user;
            // After successful login, call API
            await sendEmailToAPI(email);
            sessionStorage.setItem('user', JSON.stringify(result.user));
            sessionStorage.setItem('Paid', 'YES');  // Set the "Paid" status in sessionStorage
            navigate('/paymentdone');
        } catch (error) {
            console.error('Login failed:', error);
            navigate('/retry');
        } finally {
            setLoading(false); // Reset loading state whether success or fail
        }
    };

    const sendEmailToAPI = async (email) => {
        try {
            const response = await fetch('https://authapi-f6o7ittoia-uc.a.run.app/add-email', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email })
            });
            const data = await response.json();
            console.log('API response:', data);
        } catch (error) {
            console.error('API request failed:', error);
            throw error;  // This will handle any error and redirect to retry
        }
    };

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        width: '100vw',
    };

    const buttonStyle = {
        padding: '20px 50px',
        fontSize: '16px',
        cursor: 'pointer',
        backgroundColor: '#000000',
        color: 'white',
        border: 'none',
        borderRadius: '50px',
        marginTop: '20px',
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'Inter, sans-serif',
        fontWeight: 'bold',
    };

    const iconStyle = {
        marginRight: '10px',
        maxWidth: '30px',
    };

    const supportTextStyle = {
        marginTop: '20px',
        color: '#b9b9b9',
        fontFamily: 'Inter, sans-serif',
        fontSize: '11pt',
    };

    return (
        <div style={containerStyle}>
            <img src="logo1.svg" alt="Logo" style={{ marginBottom: '20px' }} />
            <button onClick={handleLogin} style={buttonStyle}>
                <img src="googleicon.png" alt="Google Icon" style={iconStyle} />
                Link your Google account
            </button>
            <div style={supportTextStyle}>
                If you need support contact us at hello@mailseen.com
            </div>
        </div>
    );

}

export default Paid;
