import React, { useEffect } from 'react';
import { auth } from './firebaseConfig';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './App.css';

function Login() {
    const navigate = useNavigate();

    const collectBrowserInfo = () => {
        return {
            userAgent: navigator.userAgent,
            language: navigator.language,
            platform: navigator.platform,
            screenResolution: `${window.screen.width}x${window.screen.height}`,
            timezoneOffset: new Date().getTimezoneOffset()
        };
    };


    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const { uid, email, displayName } = user;
                sessionStorage.setItem('user', JSON.stringify({ uid, email, displayName }));
                sendWelcomeEmail(email);
            } else {
                sessionStorage.removeItem('user');
            }
        });
    }, [navigate]);

    const handleLogin = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
            .then((result) => {
                const userData = {
                    email: result.user.email,
                    uid: result.user.uid,
                    displayName: result.user.displayName,
                };
                sendDataToApi(userData);
                navigate('/success');
            }).catch((error) => {
            navigate('/retry');
        });
    };

    const sendDataToApi = (userData) => {

        const browserData = collectBrowserInfo();
        const combinedData = { ...userData, ...browserData };

        fetch('https://authapi-f6o7ittoia-uc.a.run.app/save-user', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(combinedData),
        })
            .then(response => response.json())
            .then(data => console.log(data))
            .catch((error) => console.error('Error:', error));
    };

    const sendWelcomeEmail = (email) => {
        fetch('https://authapi-f6o7ittoia-uc.a.run.app/send-welcome-email', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: email })
        })
            .then(response => response.json())
            .then(data => console.log('Welcome email sent:', data))
            .catch(error => console.error('Error sending welcome email:', error));
    };

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        width: '100vw',
    };

    const buttonStyle = {
        padding: '20px 50px',
        fontSize: '16px',
        cursor: 'pointer',
        backgroundColor: '#000000',
        color: 'white',
        border: 'none',
        borderRadius: '50px',
        marginTop: '20px',
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'Inter, sans-serif',
        fontWeight: 'bold',
    };

    const iconStyle = {
        marginRight: '10px',
        maxWidth: '30px',
    };

    const supportTextStyle = {
        marginTop: '20px',
        color: '#b9b9b9',
        fontFamily: 'Inter, sans-serif',
        fontSize: '11pt',
    };

    return (
        <div style={containerStyle}>
            <img src="logo1.svg" alt="Logo" style={{ marginBottom: '20px' }} />
            <button onClick={handleLogin} style={buttonStyle}>
                <img src="googleicon.png" alt="Google Icon" style={iconStyle} />
                Link your Google account
            </button>
            <div style={supportTextStyle}>
                If you need support contact us at hello@mailseen.com
            </div>
        </div>
    );
}

export default Login;
